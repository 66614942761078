/* You can add global styles to this file, and also import other style files */

/*html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/
/** Importar el archivo SCSS para hacer la mat-table responsivas */
@import './app/shared/mat-table-responsive/mat-table-responsive.directive';

.mat-dialog-container{
  padding: 12px !important;
}
