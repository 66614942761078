.pricing-table .card {
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s;
	-webkit-border-radius: 15px;
	border-radius: 15px;
  
	.card-header {
	  border-top-left-radius: 15px;
	  border-top-right-radius: 15px;
	}
  
	.card-title {
	  font-size: 1rem;
	  letter-spacing: .2rem;
	  font-weight: 500;
	}
  
	.card-price {
	  font-size: 2.7rem;
  
	  .term {
		font-size: 0.875rem;
	  }
	}
  
	ul li.list-group-item {
	  border-bottom: 1px solid rgb(0 0 0 / 0%);
	  color: #3b3b3b;
	  font-size: 16px;
	}
  }
  
  @media (min-width: 992px) {
	.pricing-table .card:hover {
	  margin-top: -.25rem;
	  margin-bottom: .25rem;
	  -webkit-box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
	  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
	}
  }